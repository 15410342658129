var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tableSegregation"},[_c('button',{staticClass:"segregationButton",on:{"click":_vm.show}},[_vm._v(" "+_vm._s(parseInt( this.$store.state.data.items[_vm.item.positionArray].quantidade * 100 ) / 100)+" "+_vm._s(this.$store.state.data.items[_vm.item.positionArray].unidade)+" ")]),_c('modal',{attrs:{"name":`my-first-modal-${_vm.id}`,"classes":"modal","close-on-click":true,"clickToClose":false}},[_c('div',{staticClass:"modalHeader"},[_c('h3',[_vm._v("Alterar segregação")])]),_c('div',{staticClass:"modalBody"},[_c('div',{staticClass:"productData"},[_c('h2',{staticClass:"productName"},[_vm._v(_vm._s(_vm.item.descricao))]),_c('div',{staticClass:"productDescription"},[_c('div',{staticClass:"descriptionBox"},[_c('p',[_vm._v("Múltiplo")]),_c('span',[_vm._v(_vm._s(_vm.item.multiplo))])]),_c('div',{staticClass:"descriptionBox"},[_c('p',[_vm._v("Unidade")]),_c('span',[_vm._v(_vm._s(_vm.item.unidade))])])])]),_c('div',{staticClass:"segregationQuantity"},_vm._l((this.$store.state.data.items[
            _vm.item.positionArray
          ].segregacoes),function(items,index){return _c('div',{key:index,staticClass:"wrap"},[_c('div',{staticClass:"imageSegregation"},[_c('img',{attrs:{"src":_vm.getImage(items.imagem),"alt":""}})]),_c('div',{staticStyle:{"flex":"10"}},[_c('p',{staticClass:"unidade"},[_vm._v(_vm._s(items.descricao))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.segregaçãoHasMultiplos(items.multiplo) >= 1),expression:"segregaçãoHasMultiplos(items.multiplo) >= 1"}]},[_vm._v(" Múltiplo: "+_vm._s(_vm.segregaçãoHasMultiplos(items.multiplo))+" ")]),_c('div',{staticClass:"quantityAndSwitch"},[_c('div',{staticClass:"quantityCounter"},[_c('button',{staticClass:"counterButton less",style:({
                    backgroundImage:
                      'url(' + require('@/assets/icons/menos.svg') + ')',
                  }),attrs:{"disabled":!items.actionSegregation},on:{"click":() => {
                      _vm.less(items, _vm.id);
                    }}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim.number",value:(items.volatil),expression:"items.volatil",modifiers:{"trim":true,"number":true}}],staticClass:"quantity",class:{
                    disable: items.actionSegregation === true ? false : true,
                  },attrs:{"min":"0","type":"number","disabled":!items.actionSegregation},domProps:{"value":(items.volatil)},on:{"blur":[(e) => _vm.handleQuantity(e, index, _vm.item, items),function($event){return _vm.$forceUpdate()}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return ((e) => _vm.handleQuantity(e, index, _vm.item, items)).apply(null, arguments)},"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing)return;_vm.$set(items, "volatil", _vm._n($event.target.value.trim()))}}}),_c('button',{staticClass:"counterButton more",style:({
                    backgroundImage:
                      'url(' + require('@/assets/icons/mais.svg') + ')',
                  }),attrs:{"disabled":!items.actionSegregation},on:{"click":() => {
                      _vm.more(items, _vm.id);
                    }}})]),_c('md-switch',{attrs:{"disabled":_vm.item.disabled},on:{"change":(e) => _vm.switchClick(e, index, items, _vm.item)},model:{value:(items.actionSegregation),callback:function ($$v) {_vm.$set(items, "actionSegregation", $$v)},expression:"items.actionSegregation"}})],1)])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.quantidaMaxSegregacoes),expression:"quantidaMaxSegregacoes"}],staticClass:"qtdMaxSegregacao"},[_vm._v(" Selecione no máximo "+_vm._s(_vm.quantidaMaxSegregacoes)+" segregações ")]),_c('div',{staticClass:"totalQuantity"},[_c('p',[_vm._v("Quantidade total")]),_c('span',[_vm._v(_vm._s(parseInt( this.$store.state.data.items[ _vm.item.positionArray ].segregacoes.reduce((old, news) => old + news.volatil, 0) * 100 ) / 100))])]),_c('div',{staticClass:"modalFooter"},[_c('md-button',{staticClass:"cancel",on:{"click":() => _vm.hide(_vm.id)}},[_vm._v("cancelar")]),_c('md-button',{staticClass:"save",on:{"click":_vm.updateQuantity}},[_vm._v("confirmar")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }