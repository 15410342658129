export default function getImage(item) {
  if (item === "" || item === undefined || item === null) {
    return require("@/assets/images/placeholder-produto.png");
  } else {
    if (item.match(/https:/g)) {
      return `${item}`;
    } else {
      if (item.match(/data:image/g)) {
        return `${item}`;
      } else {
        return `${item}`;
      }
    }
  }
}
