var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_top"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMinimal),expression:"showMinimal"}],staticClass:"minimalContent"},[_c('div',{staticClass:"headerTopCol"},[_c('div',{staticClass:"enterpriseDescription"},[_c('span',{staticClass:"enterpriseDescription_line_bold"},[_vm._v(_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.nomeEmpresa)))])])]),_c('div',{staticClass:"headerTopCol"},[_c('div',{staticClass:"clientDescription"},[_c('span',{staticClass:"clientDescription_line"},[_vm._v("Cliente: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.clienteRazaoSocial))+" ")])])]),_c('md-button',{staticClass:"md-icon-button arrowUp",on:{"click":_vm.showMinimalHeader}},[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMinimal),expression:"!showMinimal"}]},[_vm._v("keyboard_arrow_up")]),_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMinimal),expression:"showMinimal"}]},[_vm._v("keyboard_arrow_down")])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMinimal),expression:"!showMinimal"}],staticClass:"maxContent"},[_c('div',{staticClass:"headerTopCol"},[_c('div',{staticClass:"enterpriseDescription"},[(
            this.$store.state.data.imagemEmpresa === '' ||
            this.$store.state.data.imagemEmpresa === undefined ||
            this.$store.state.data.imagemEmpresa === null
              ? false
              : true
          )?_c('img',{staticClass:"enterpriseLogo",attrs:{"src":_vm.getImage(this.$store.state.data.imagemEmpresa),"alt":""}}):_vm._e()]),_c('span',{staticClass:"enterpriseDescription_line_bold"},[_vm._v(_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.nomeEmpresa)))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.stringEmptyToHypen(this.$store.state.data.representanteNome) === '-'
            ? false
            : true
        ),expression:"\n          stringEmptyToHypen(this.$store.state.data.representanteNome) === '-'\n            ? false\n            : true\n        "}],staticClass:"enterpriseDescription_line"},[_vm._v(_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.representanteNome))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.stringEmptyToHypen(this.$store.state.data.cnpjEmpresa) === '-'
            ? false
            : true
        ),expression:"\n          stringEmptyToHypen(this.$store.state.data.cnpjEmpresa) === '-'\n            ? false\n            : true\n        "}],staticClass:"enterpriseDescription_line"},[_vm._v(" "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.cnpjEmpresa)))])]),_c('div',{staticClass:"headerTopCol clientDescription"},[_c('span',{staticClass:"clientDescription_line"},[_vm._v("Cliente: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.clienteRazaoSocial)))]),_c('span',{staticClass:"clientDescription_line"},[_vm._v("Email: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.clienteEmail))+" ")]),_c('span',{staticClass:"clientDescription_line"},[_vm._v("Tipo de ordem: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.tipoOrdem))+" ")]),_c('span',{staticClass:"clientDescription_line"},[_vm._v("Tipo de frete: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.tipoFrete))+" ")]),_c('span',{staticClass:"clientDescription_line"},[_vm._v("Forma de pagamento: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.formaPagamento))+" ")]),_c('span',{staticClass:"clientDescription_line"},[_vm._v("Condição de pagamento: "+_vm._s(_vm.stringEmptyToHypen(this.$store.state.data.condicaoPagamento))+" ")]),_c('span',{staticClass:"clientDescription_line expireDate"},[_vm._v(" Este orçamento expira no dia: "+_vm._s(this.$store.state.data.dataExpira))]),_c('md-button',{staticClass:"md-icon-button arrowUp",on:{"click":_vm.showMinimalHeader}},[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMinimal),expression:"!showMinimal"}]},[_vm._v("keyboard_arrow_up")]),_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMinimal),expression:"showMinimal"}]},[_vm._v("keyboard_arrow_down")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }